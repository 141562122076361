import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import useStaticImage from '../../../hooks/useStaticImage'
import './styles.scss'

const WeekInfo = ({ src, alt, week, title, description, alignRight }) => {
  const [getImage] = useStaticImage()

  return (
    <tr className="week-info">
      <td
        className={`week-info__content ${
          alignRight ? 'week-info__content--right' : 'week-info__content--left'
        }`}
      >
        <h5 className="week-info__title">
          {week} - {title}
        </h5>
        <div className="week-info__description">{description}</div>
      </td>
      <td className="week-info__icon-container">
        <GatsbyImage image={getImage(src)} alt={alt} className='week-info__image-container' imgClassName="week-info__image" />
      </td>
    </tr>
  )
}

export default WeekInfo
