import { GatsbyImage } from 'gatsby-plugin-image'
import * as React from 'react'
import useStaticImage from '../../../hooks/useStaticImage'
import './styles.scss'

const IconsAIServices = ({ src, alt, title, description }) => {
  const [getImage] = useStaticImage()

  return (
    <div className="icons-ai-services">
      <GatsbyImage image={getImage(src)} alt={alt} className="icons-ai-services__image" />
      <h4 className="icons-ai-services__title">{title}</h4>
      <p className="icons-ai-services__description">{description}</p>
    </div>
  )
}

export default IconsAIServices
