import * as React from 'react'
import {
  Introduction,
} from '../../components/molecules/Cases'
import IconsAIServices from '../../components/molecules/IconsAIServices'
import SectionContainer from '../../components/molecules/SectionContainer'
import WeekInfo from '../../components/molecules/WeekInfo'
import Infos from "./infos.json"
import './styles.scss'

const Content = () => {

  const Intro = () => (
    <Introduction
      Title={() => (
        <h1>
          AI Labs <strong>| Transformando Ideias em MVPs Funcionais</strong>
        </h1>
      )}
    />
  );

  const KnowAILabs = () => (
    <SectionContainer className="ai-labs__know-us">
      <div id="race-control">
        <h3>Conheça o AI Labs</h3>
        <p>
          O AI Labs é um Serviço de Discovery e criação de MVP totalmente personalizado, com foco na co-criação da solução junto aos envolvidos nos processos do cliente.
        </p>
        <p>
          Utilizamos a metodologia de design thinking para entender e desenhar soluções conectadas diretamente às necessidades específicas do cliente.
        </p>
        <p>
          Em paralelo, especialistas em IA e desenvolvimento de software trabalham para identificar as melhores estratégias e ferramentas que agreguem valor rapidamente e permitam evolução contínua. A entrega final é um MVP funcional que já proporciona valor ao cenário estudado.
        </p>
        <p>
          O AI Labs está comprometido em acelerar a inovação, aprimorar processos e forjar novos caminhos para o sucesso empresarial em um cenário tecnológico em constante evolução.
        </p>
      </div>
    </SectionContainer>
  );

  const AIInfoContainer = () => (
    <SectionContainer className="ai-labs__features">
      <h3>Inove e otimize com Tecnologia de Ponta</h3>
      <div className="ai-labs__features-container">
      {Infos.serviceFeatureData.map((info, index) => (
          <IconsAIServices
            key={index}
            src={info.src}
            alt={info.alt}
            title={info.title}
            description={info.description}
          />
        ))}
      </div>
    </SectionContainer>
  );

  const AILabsSchedule = () => (
    <SectionContainer className="ai-labs__schedule">
      <h3 id="dev-race-control">Cronograma de fases do AI Labs</h3>
      <p>
        A abordagem de sete semanas do IA Labs é uma forma inovadora de desenvolver rapidamente soluções de IA personalizadas. Este cronograma é vantajoso por sua eficiência, permitindo uma progressão rápida do conceito para um produto funcional, alinhando com as mudanças rápidas no mercado e das necessidades empresariais. Essa abordagem é especialmente atrativa para a alta administração.
      </p>
      <table className="ai-labs__schedule-container">
      <tbody>
          {Infos.weeksInfoData.map((info, index) => (
            <WeekInfo
              key={index}
              src={info.src}
              alt={info.alt}
              week={info.week}
              title={info.title}
              description={info.description}
              alignRight={info.alignRight}
            />
          ))}
        </tbody>
      </table>
    </SectionContainer>
  );

  return (
    <>
      <Intro />
      <KnowAILabs />
      <AIInfoContainer />
      <AILabsSchedule />
    </>
  );
};

export default Content;